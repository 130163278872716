// Lib
import { FC, useEffect, useRef } from "react";
import { Col, Radio, RadioChangeEvent, Row, Space } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// Hooks
import { usePermissions, useViewport } from "hooks";
// Types
import { ProductFormTypes } from "./types";
import {
  GetProductLabelsResponse,
  GetProductTagsListResponse,
} from "types/products";
// Constants
import { ADMIN_ROUTES } from "consts";
// Icons
import { RightBurgerMenuIcon } from "icons";
// Components
import { DropDown } from "components";
import {
  ImageUpload,
  Input,
  Select,
  TextArea,
  InputNumber,
  CheckboxWithTheImage,
} from "components/Form";
// Styled
import { Button } from "styled/Buttons";
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { ButtonsContainer, UploadContainer } from "./styled";
import { Title } from "components/MenuItemCard/styled";

import { defaultValues } from "./defaultValues";
import { resolver } from "./validation";

const placeHolderValue = "Enter amount";

interface ProductFormProps {
  highlightsData: GetProductTagsListResponse;
  alergentsData: GetProductTagsListResponse;
  labelsData: GetProductLabelsResponse;
  initialData?: ProductFormTypes;
  handleSendRequest: (data: ProductFormTypes) => void;
}

export const ProductForm: FC<ProductFormProps> = ({
  highlightsData,
  alergentsData,
  labelsData,
  initialData,
  handleSendRequest,
}) => {
  const { isDesktop, isTabletPortrait } = useViewport();

  const navigate = useNavigate();
  const { id } = useParams();

  const isEditMode = !!id;

  const { canUpdateProduct, canCreateProduct } = usePermissions();

  const formSetted = useRef(false);

  const { handleSubmit, reset, control, watch, setValue } =
    useForm<ProductFormTypes>({
      defaultValues: initialData ? initialData : defaultValues,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  useEffect(() => {
    if (initialData && !formSetted.current) {
      formSetted.current = true;
      reset(initialData);
    }
  }, [initialData]);

  const onSubmit: SubmitHandler<ProductFormTypes> = async data => {
    handleSendRequest(data);
  };

  const setImage = (image: string) => {
    setValue("imageUrl", image);
  };

  const dropDownMenuItems = [
    {
      key: "2",
      label: "Discard",
      onClick: () => navigate(ADMIN_ROUTES.PRODUCTS.path),
    },
    {
      key: "3",
      label: "Save",
      onClick: () => handleSubmit(onSubmit)(),
    },
  ];

  const image = watch("imageUrl");

  const disabled = isEditMode ? !canUpdateProduct : !canCreateProduct;

  return (
    <FlexContainer
      $fullwidth
      $column
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={isTabletPortrait ? 24 : 16}>
          <FlexContainer $column $gap={16}>
            <ContentBox $column>
              <Title>General</Title>

              <FlexContainer $gap={16} $column={!isDesktop}>
                <FlexContainer>
                  <UploadContainer>
                    <ImageUpload
                      disabled={disabled}
                      value={image}
                      onChange={setImage}
                    />
                  </UploadContainer>
                </FlexContainer>

                <FlexContainer $column $gap={16} $fullwidth>
                  <Controller
                    name={"name"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        required
                        label="English name"
                        placeholder="Enter food name"
                        {...field}
                        disabled={disabled}
                        fieldState={fieldState}
                      />
                    )}
                  />
                  <Controller
                    name={"name2"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        label="Arabic name"
                        dir="rtl"
                        placeholder="Enter food name"
                        {...field}
                        disabled={disabled}
                        fieldState={fieldState}
                      />
                    )}
                  />

                  <Controller
                    name={"plu"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        label="PLU"
                        placeholder="Enter PLU"
                        {...field}
                        disabled
                        fieldState={fieldState}
                      />
                    )}
                  />

                  <Controller
                    name={"description"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextArea
                        label="Descriptions"
                        placeholder="Enter descriptions"
                        {...field}
                        disabled={disabled}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </FlexContainer>
              </FlexContainer>
            </ContentBox>

            <ContentBox $column>
              <FlexContainer $fullwidth $gap={16} $column>
                <Title>Pricing</Title>

                <InputsGridContainer>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        required
                        label="Base price (KWD)"
                        placeholder="Enter price"
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v => setValue("price", v?.toString() || "")}
                      />
                    )}
                  />

                  <Controller
                    name="tax"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        required
                        label="Delivery tax (%)"
                        placeholder="Enter tax"
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v => setValue("tax", v?.toString() || "")}
                      />
                    )}
                  />

                  <Controller
                    name="externalFiatPrice"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="External price (KWD)"
                        placeholder="Enter price"
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v =>
                          setValue("externalFiatPrice", v?.toString() || "")
                        }
                      />
                    )}
                  />
                </InputsGridContainer>
              </FlexContainer>
            </ContentBox>

            <ContentBox $column>
              <FlexContainer $fullwidth $gap={16} $column>
                <Title>Nutrional details</Title>

                <InputsGridContainer>
                  <Controller
                    name="fiber"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Fiber (g)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v => setValue("fiber", v?.toString() || "")}
                      />
                    )}
                  />

                  <Controller
                    name="sugar"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Sugar (g)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v => setValue("sugar", v?.toString() || "")}
                      />
                    )}
                  />

                  <Controller
                    name="cholesterol"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Cholesterol (mg)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v =>
                          setValue("cholesterol", v?.toString() || "")
                        }
                      />
                    )}
                  />

                  <Controller
                    name="zinc"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Zinc (%)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v => setValue("zinc", v?.toString() || "")}
                      />
                    )}
                  />

                  <Controller
                    name="calsium"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Calsium (%)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v => setValue("calsium", v?.toString() || "")}
                      />
                    )}
                  />

                  <Controller
                    name="pottasium"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Pottasium (mg)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v =>
                          setValue("pottasium", v?.toString() || "")
                        }
                      />
                    )}
                  />

                  <Controller
                    name="vitaminA"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Vitamin A (%)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v =>
                          setValue("vitaminA", v?.toString() || "")
                        }
                      />
                    )}
                  />

                  <Controller
                    name="vitaminC"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        label="Vitamin A (%)"
                        placeholder={placeHolderValue}
                        step={0.001}
                        precision={3}
                        {...field}
                        value={Number(field.value) || 0}
                        disabled={disabled}
                        fieldState={fieldState}
                        onChange={v =>
                          setValue("vitaminC", v?.toString() || "")
                        }
                      />
                    )}
                  />
                </InputsGridContainer>
              </FlexContainer>
            </ContentBox>
          </FlexContainer>
        </Col>

        <Col span={isTabletPortrait ? 24 : 8}>
          <FlexContainer $column $gap={16}>
            <ContentBox $column>
              <FlexContainer $fullwidth $gap={16} $column>
                <Title>Status</Title>

                <Controller
                  name={"status"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Select
                      required
                      label="Status"
                      {...field}
                      disabled={disabled}
                      fieldState={fieldState}
                      defaultValue="disabled"
                      options={[
                        { label: "Active", value: "active" },
                        { label: "Disabled", value: "disabled" },
                      ]}
                    />
                  )}
                />
              </FlexContainer>
            </ContentBox>

            <ContentBox $column>
              <FlexContainer $fullwidth $gap={16} $column>
                <Title>Macro details</Title>

                <Controller
                  name="kcal"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      required
                      label="Total Calorie (kcal)"
                      placeholder="Enter total calorie"
                      step={0.001}
                      precision={3}
                      {...field}
                      value={Number(field.value) || 0}
                      disabled={disabled}
                      fieldState={fieldState}
                      onChange={v => setValue("kcal", v?.toString() || "")}
                    />
                  )}
                />

                <Controller
                  name="carbs"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      label="Carbs (g)"
                      placeholder="Enter amount of carbs"
                      step={0.001}
                      precision={3}
                      {...field}
                      value={Number(field.value) || 0}
                      disabled={disabled}
                      fieldState={fieldState}
                      onChange={v => setValue("carbs", v?.toString() || "")}
                    />
                  )}
                />

                <Controller
                  name="protein"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      label="Protein (g)"
                      placeholder="Enter amount of protein"
                      step={0.001}
                      precision={3}
                      {...field}
                      value={Number(field.value) || 0}
                      disabled={disabled}
                      fieldState={fieldState}
                      onChange={v => setValue("protein", v?.toString() || "")}
                    />
                  )}
                />

                <Controller
                  name="fat"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      label="Fat (g)"
                      placeholder="Enter amount of fat"
                      step={0.001}
                      precision={3}
                      {...field}
                      value={Number(field.value) || 0}
                      disabled={disabled}
                      fieldState={fieldState}
                      onChange={v => setValue("fat", v?.toString() || "")}
                    />
                  )}
                />
              </FlexContainer>
            </ContentBox>

            {!!highlightsData?.length && (
              <ContentBox $column>
                <FlexContainer $fullwidth $gap={16} $column>
                  <Title>Nutritional highlights</Title>

                  {highlightsData?.map(tag => (
                    <Controller
                      key={tag.id}
                      name={`highlights.${tag.id}`}
                      control={control}
                      render={({ field }) => (
                        <CheckboxWithTheImage
                          label={tag.name}
                          imageUrl={tag?.imageUrl}
                          disabled={disabled}
                          field={field}
                        />
                      )}
                    />
                  ))}
                </FlexContainer>
              </ContentBox>
            )}

            {!!alergentsData?.length && (
              <ContentBox $column>
                <FlexContainer $fullwidth $gap={16} $column>
                  <Title>Alergents</Title>

                  {alergentsData?.map(tag => (
                    <Controller
                      key={tag.id}
                      name={`alergents.${tag.id}`}
                      control={control}
                      render={({ field }) => (
                        <CheckboxWithTheImage
                          label={tag.name}
                          imageUrl={tag?.imageUrl}
                          disabled={disabled}
                          field={field}
                        />
                      )}
                    />
                  ))}
                </FlexContainer>
              </ContentBox>
            )}

            {!!labelsData?.length && (
              <ContentBox $column>
                <FlexContainer $fullwidth $gap={16} $column>
                  <Title>Label</Title>

                  <Controller
                    name={"labels"}
                    control={control}
                    render={({ field }) => (
                      <Radio.Group
                        value={field.value}
                        onChange={(e: RadioChangeEvent) =>
                          field.onChange(e.target.value)
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={""}>
                            <Typography.Title>None</Typography.Title>
                          </Radio>

                          {labelsData.map(({ id, name }) => (
                            <Radio key={id} value={id}>
                              <Typography.Title>{name}</Typography.Title>
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    )}
                  />
                </FlexContainer>
              </ContentBox>
            )}
          </FlexContainer>
        </Col>
      </Row>

      <ButtonsContainer $gap={10}>
        {isDesktop ? (
          <>
            <Button.Heading
              onClick={() => navigate(ADMIN_ROUTES.PRODUCTS.path)}
            >
              Discard
            </Button.Heading>
            <Button.Heading
              type="primary"
              htmlType="submit"
              disabled={disabled}
            >
              Save
            </Button.Heading>
          </>
        ) : (
          <>
            <DropDown trigger={["click"]} items={dropDownMenuItems}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          </>
        )}
      </ButtonsContainer>
    </FlexContainer>
  );
};
